import { AUTH_ACTIONS } from '../../../store/actions'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import axios from 'axios'
import querystring from 'query-string'
import conf from '../../../config'
export const AUTHURL = `${conf.AUTHURL}`
export const AUTHCLIENTID = `${conf.AUTHCLIENTID}`
export const AUTHCLIENTSECRET = `${conf.AUTHCLIENTSECRET}`
//var querystring = require('querystring');
export default {
  data() {
    return {
      signedIn: false,
      email: '',
      password: '',
      mustSelectUserType: false,
      userData: null
    }
  },
  components: {
    ////'userType': selectUserTypesComponent
  
  },
  mounted() {
    //debugger
    if(this.$recaptchaInstance != undefined)
    {
      this.$recaptchaInstance.hideBadge()
    }
    // const recaptcha = this.$recaptchaInstance
    //     recaptcha.hideBadge()
},
  created() {
    debugger
   console.clear();
  },
  methods: {
    signUp() {
      this.$router.push({ name: 'Signup' })
    },
    async isUserSignedIn() {
      try {
        const userObj = Auth.currentAuthenticatedUser();
        this.signedIn = true;
        this.$router.push({ name: 'Recalls' })
      }
      catch (err) {
       console.clear();
        this.signedIn = false;
      }
    },
    async onSubmitLogin() {

      const isValid = await this.$refs['observer-login'].validate()
      if (!isValid) {
        this._showToast('Please check your information', { variant: 'warning' })
      } else {
        if (this.email == "") {
          this._showToast('Email is required', { variant: 'danger', title: 'Error' })
        }
        else if (this.password == "") {
          this._showToast('Password is required', { variant: 'danger', title: 'Error' })
        }
        else {
          this._loadingStart()
          //await axios.post('https://notilite-dev.auth.us-east-2.amazoncognito.com/oauth2/token',
          //await axios.post('https://notilite-nterprod.auth.us-east-2.amazoncognito.com/oauth2/token',
          await axios.post(AUTHURL,
                    querystring.stringify({
                      grant_type: 'client_credentials', //gave the values directly for testing
                      //QA
                      // client_secret: '1o916rcvlt746ceg9hha9mta2njmqmsj3rk556tv4covhsnqfv10',
                      //       client_id: '25a3quae49ni29dddot9j68del'
                      //Prod
                            // client_secret: '1t85gf7l3p23ot4lajmjogniomus0jdqd0hb5cl5g3jksk5gqfgk',
                            // client_id: '4houn954t0d58i67o7lcge4cra'
                            client_secret: AUTHCLIENTSECRET,
                            client_id: AUTHCLIENTID
                    }), {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      }
                    }).then(function(response) {
                      debugger
                      //access_token = response
                        console.log(response);
                        localStorage.setItem("OAuthAccessToken", response.data.access_token);
                    }).catch(err => {
                        debugger
                        console.log(err);  
                      });
                      this._loadingStart()
          var user1 = Auth.signIn({
            username: this.email,
            password: this.password
          })
            .then(data => {
              AmplifyEventBus.$emit('localUser', data.user);
              if (data.userConfirmed === false) {
                //debugger
                AmplifyEventBus.$emit('authState', 'confirmSignUp');
               console.clear();
              } else {
                Auth.currentSession()
                  .then(data1 => {
                    //debugger
                    //console.log(data1)
                  })
                  .catch(err => {
                    //debugger
                    this._loadingEnd()
                    this._showToast(err.message, { variant: 'danger', title: 'Error' })
                  });
                AmplifyEventBus.$emit('authState', 'signIn');
                //debugger
                //var access_token = ''
              //   const sendGetRequest = async () => {
              //     try {
              //         const resp = await axios.post('https://notilite-dev.auth.us-east-2.amazoncognito.com/oauth2/token',
              //         querystring.stringify({
              //           grant_type: 'client_credentials', //gave the values directly for testing
              //           client_secret: '1o916rcvlt746ceg9hha9mta2njmqmsj3rk556tv4covhsnqfv10',
              //                 client_id: '25a3quae49ni29dddot9j68del'
              //         }), {
              //           headers: {
              //             "Content-Type": "application/x-www-form-urlencoded"
              //           }
              //         });
              //         //debugger
              //         console.log(resp.data.access_token);
              //         //localStorage.setItem("OAuthAccessToken", resp.data.access_token);
              //     } catch (err) {
              //         // Handle Error Here
              //         debugger
              //         console.error(err);
              //     }
              // };
              // debugger
              // sendGetRequest();
              // debugger
                  // axios.post('https://notilite-dev.auth.us-east-2.amazoncognito.com/oauth2/token',
                  //   querystring.stringify({
                  //     grant_type: 'client_credentials', //gave the values directly for testing
                  //     client_secret: '1o916rcvlt746ceg9hha9mta2njmqmsj3rk556tv4covhsnqfv10',
                  //           client_id: '25a3quae49ni29dddot9j68del'
                  //   }), {
                  //     headers: {
                  //       "Content-Type": "application/x-www-form-urlencoded"
                  //     }
                  //   }).then(function(response) {
                  //     debugger
                  //     //access_token = response
                  //       console.log(response);
                  //   }).catch(err => {
                  //       debugger
                  //       console.log(err);  
                  //     });
                var UserData = [];
                UserData.full_name = data.attributes['custom:firstname'] + " " + data.attributes['custom:lastname'];
                UserData.fname = data.attributes['custom:firstname'];
                UserData.lname = data.attributes['custom:lastname'];
                UserData.email = data.attributes['email'];
                UserData.title = data.attributes['custom:title'];
                UserData.companyname = data.attributes['custom:companyname'];
                UserData.phoneno = data.attributes['phone_number'];
                localStorage.setItem('storedData', data.attributes['custom:firstname'] + " " + data.attributes['custom:lastname'])
                var userD = [];
                userD[0] = UserData.full_name;
                userD[1] = UserData.fname;
                userD[2] = UserData.lname;
                userD[3] = UserData.email;
                userD[4] = UserData.title;
                userD[5] = UserData.companyname;
                userD[6] = UserData.phoneno;
                localStorage.setItem("userD", JSON.stringify(userD));
                localStorage.setItem('userId', data.username)
                this.$store.commit('UPDATE_USER_PROFILE', UserData)
                debugger
                this.$store.dispatch(AUTH_ACTIONS.LOGIN_LITE, {
                  client_id: 'web-client',
                  grant_type: 'password',
                  email: this.email,
                  password: this.password,
                  refresh_token: { access_token: localStorage.getItem("OAuthAccessToken"), refresh_token: data.signInUserSession.accessToken.jwtToken },
                  //refresh_token: { access_token: data.signInUserSession.accessToken, refresh_token: data.signInUserSession.accessToken.jwtToken },
                  login_as: '',
                  login_as_org_id: 0
                })
                this.$router.push({ name: 'Recalls' })
              }
              return data;
            })
            .catch(err => {
             console.clear();
              //debugger
              if (err.message == "Cannot read property 'auth' of undefined") {
                this.$router.push({ name: 'Recalls' })
              }
              else if (err.message == "Incorrect username or password.") {
                this._loadingEnd()
                this._showToast("Incorrect username or password.", { variant: 'danger', title: 'Error' })
              }
              else if (err.message == "User does not exist.") {
                this._loadingEnd()
                this._showToast("User doesn't exists in the application.", { variant: 'danger', title: 'Error' })
              }
              else if (err.message == "User is not confirmed.") {
                //debugger
                this._loadingEnd()
                this._showResend('', { title: 'Account Verification Pending', variant: '' }).then(confirm => {
                  if (confirm) {
                    //debugger
                     Auth.resendSignUp(this.email);
                    console.clear();
                     this._showToast('Confirmation email send successfully, please check your mailbox', { variant: 'warning' })
                      // userServices.deleteByGUID(val).then(resp => {
                      //     this.fetchDelay("Recall deleted successfully")
                      // })
                  }
              })
                //this._showToast("This email has not been verified. If you have registered your email, please check your inbox for the verification email. Otherwise please register.", { variant: 'danger', title: 'Error' })
              }
              //
              else {
                this.$router.push({ name: 'Recalls' })
              }
            });
        }

      }
    },
    IsSignedIn() {
      AmplifyEventBus.$on('authState', info => {
        if (info === 'signIn') {
          this.isUserSignedIn();
          this.$router.push({ name: 'Recalls' })
        }
        else {
          this.signedIn = false;
        }
      })
    },
    confirmSignUp() {
      Auth.confirmSignUp(this.email, this.password).then(data => {
        AmplifyEventBus.$emit('authState', 'signIn')
        return data // 'SUCCESS'
      })
        .catch(err => {
        });
    },
    hidePopup() {
      this.mustSelectUserType = false
    }
  },
  computed: {
  },
  beforeMount() {
  },
  watch: {
  }
}